import React from 'react'
import Index from '../Utils/Header_Footer/Index'
import Homeindex from '../Components/Home/Home_index'
const Home = () => {
  return (
    <Index>
      <Homeindex/>  
    </Index>
  )
}

export default Home