import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import Serviceone from '../../Assets/icons/service_one.svg'
import Service2 from '../../Assets/icons/service_2.svg'
import Service3 from '../../Assets/icons/service_3.svg'
import Service4 from '../../Assets/icons/service_4.svg'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
const OurServiceSwiperr = () => {
  return (
    <>
        <Swiper
        spaceBetween={120}
        navigation
        pagination={
            {
                clickable:true
            }
        }
        modules={[Pagination,Navigation]}
        className="mySwiper container"
        breakpoints={{
                576: {
                    slidesPerView: 1,
                    },
                600: {
                    slidesPerView: 1,
                    },
                768: {
                    pagination:false,
                    slidesPerView: 3,
                    },
                900: {
                    spaceBetween:50,
                    slidesPerView: 2,
                    },
                1200: {
                    spaceBetween:50,
                    slidesPerView: 4
                    },
            }}
            style={{
                height:'300px'
            }}
        >
            <SwiperSlide className='text-white Our-services-font-style'>
                <div className='d-flex justify-content-center align-items-center '>
                    <img className='img-fluid services-image' src={Serviceone} alt='Crypto funding talent funding program'/>
                </div>
                <main className='d-flex flex-column justify-content-center align-items-center'>
                    <h3 className='services-title'>Funding program :</h3>
                    <p className='text-center px-3'>get funded and keep up to 95% of the profit split </p>
                </main>
            </SwiperSlide>
            <SwiperSlide className='text-white Our-services-font-style'>
                <div className='d-flex justify-content-center align-items-center'>
                    <img className='img-fluid services-image' src={Service2} alt='Crypto funding talent hold and earn'/>
                </div>
                <main className='d-flex flex-column justify-content-center align-items-center'>
                    <h3 className='services-title'>Hold & earn :</h3>
                    <p className='text-center px-3'>70% of the platform income are delivered to our Token holders</p>
                </main>
            </SwiperSlide>
            <SwiperSlide className='text-white Our-services-font-style'>
                <div className='d-flex justify-content-center align-items-center'>
                    <img className='img-fluid services-image' src={Service3} alt='Crypto funding talent social trade'/>
                </div>
                <main className='d-flex flex-column justify-content-center align-items-center'>
                    <h3 className='services-title'>Social trade :</h3>
                    <p className='text-center px-3'>copy our top funded trader and achieve the same returns without any efforts </p>
                </main>
            </SwiperSlide>
            <SwiperSlide className='text-white Our-services-font-style'>
                <div className='d-flex justify-content-center align-items-center'>
                    <img className='img-fluid services-image' src={Service4} alt='Crypto funding talent analysis and ideas sharing'/>
                </div>
                <main className='d-flex flex-column justify-content-center align-items-center'>
                    <h3 className='services-title'>analysis and ideas sharing: </h3>
                    <p className='text-center px-3'>Share your Trading Ideas with others and earn reward and donation </p>
                </main>
            </SwiperSlide>
            
            
            
            
        </Swiper>
    </>
  )
}

export default OurServiceSwiperr