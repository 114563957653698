import React from 'react'
import {Chart as ChartJs, Tooltip, Title, ArcElement, Legend} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJs.register(
    Tooltip, Title, ArcElement
  );
  const data = {
    datasets: [{
        data: [25, 20, 13,12,10,7,5,5,3],
        backgroundColor:[
          '#004FBD',
          '#0065F2',
          '#197AFF',
          '#4794FF',
          '#7DB4FF',
          '#7DB4FF',
          '#8FBEFF',
          '#A3C9FF',
          '#D2E5FF'
        ],
        borderWidth: 0,
        cutout: 115
    },
  ],
  // These labels appear in the legend and in the tooltips when hovering different arcs
  labels: [
      'Public Sales',
      'Liquidity',
      'Staking',
      'Team',
      'Marketing',
      'Reserve',
      'Strategic Partners',
      'Bounty',
      'Advisors'
  ], 

};
const Tokenomicschart = () => {
  return (
    <>
        <Doughnut data={data}/>
    </>
  )
}

export default Tokenomicschart