import React from 'react'
import Logo from '../../../Assets/images/Logo.svg'
const Footer = () => {
  return (
    <footer className='container'>
        <div className='row footer-styling'>
            <div className='col-lg-3 col-6'>
                <div className='mb-3'>
                    <img className='img-fluid' src={Logo} alt='Crypto funding talent logo'/>
                </div>
                <p className='text-white'>
                    Stórhöfði 33
                    110 Reykjavík, Iceland
                    Tel: +354 587 9999
                    info@mountainguides.is
                </p>
            </div>
            <div className='col-lg-3 col-6 ps-5'>
                <h5 className='text-white mb-3'>First Column</h5>
                <nav className='d-flex flex-column footer-links-font'>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                </nav>
            </div>
            <div className='my-4 d-lg-none d-flex justify-content-center'>
                <hr className='text-white'/>
            </div>
            <div className='col-lg-3 col-6'>
                <h5 className='text-white mb-3'>First Column</h5>
                <nav className='d-flex flex-column footer-links-font'>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                </nav>
            </div>
            <div className='col-lg-3 col-6 ps-5'>
                <h5 className='text-white mb-3'>First Column</h5>
                <nav className='d-flex flex-column footer-links-font'>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                    <a className='text-white text-decoration-none mb-2' href='#'>First page</a>
                </nav>
            </div>
        </div>
        <main className='row py-4 copyright-styling mt-4'>
            <span className='text-white col-lg-6 col-12 mb-lg-0 mb-3'>© 2020 Acme. All right reserved.</span>
            <nav className='col-lg-6 col-12 d-flex flex-lg-row flex-column justify-content-lg-end justify-content-start'>
                <a className='text-white text-decoration-none me-3 mb-lg-0 mb-3' href='#'>Privacy Policy</a>
                <a className='text-white text-decoration-none mb-lg-0 mb-3' href='#'>Terms of Service</a>
            </nav>
        </main>
    </footer>
  )
}

export default Footer