import React,{useEffect} from 'react'
import Tokenomicschart from '../../../Utils/charts/Tokenomicschart'
import Aos from 'aos'
const Tokenomics = () => {
    useEffect(
        ()=>{
          Aos.init({duration:1000})
        }
      ,[])
  return (
    <section className='container text-white tokenomics-styling'>
        <main className='d-flex justify-content-center align-items-center'>
            <h2 className='text-center'>Tockenomics</h2>
        </main>
        <div className='row gap-4 justify-content-center mt-5'>
            <div className='col-lg-4 col-12 tokenomics-card' data-aos="flip-left">
                <main className='d-flex flex-column justify-content-center align-items-center'>
                    <h4>VALUATION</h4>
                    <span>10,000,000$</span>
                </main>
            </div>
            <div className='col-lg-4 col-12 tokenomics-card bg-white' data-aos="zoom-in">
                <main className='d-flex flex-column justify-content-center align-items-center'>
                    <h4 className='text-primary'>MCAP (TGE)</h4>
                    <span className='text-black'>25,000,000$</span>
                </main>
            </div>
            <div className='col-lg-4 col-12 tokenomics-card' data-aos="flip-right">
                <main className='d-flex flex-column justify-content-center align-items-center'>
                    <h4>VALUATION</h4>
                    <span>10,000,000$</span>
                </main>
            </div>
        </div>
        <div className='row mt-5'>
            <div className='col-12 d-lg-flex d-none justify-content-center order-lg-1 order-2 px-0 mb-lg-5 mb-0' data-aos="zoom-in">
                <main className='tokenomic-state d-flex flex-row justify-content-between align-items-center'>
                    <span>25%</span>
                    <div className='d-flex flex-row'>
                        <span className='me-3'>Public Sales</span>
                        <div className='public-sale-color'></div>
                    </div>
                </main>
            </div>
            <div className='col-lg-4 col-12 d-flex flex-column justify-content-center align-items-center order-lg-2 order-3' data-aos="fade-right">
                    <main className='tokenomic-state d-lg-none  d-flex flex-row justify-content-between align-items-center'>
                        <span>25%</span>
                        <div className='d-flex flex-row'>
                            <span className='me-3'>Public Sales</span>
                            <div className='public-sale-color'></div>
                        </div>
                    </main>
                    <main className='tokenomic-state d-flex flex-row justify-content-between align-items-center'>
                        <span>20%</span>
                        <div className='d-flex flex-row'>
                            <span className='me-3'>Liquidity</span>
                            <div className='Liquidity-color'></div>
                        </div>
                    </main>
                    <main className='tokenomic-state d-flex flex-row justify-content-between align-items-center'>
                        <span>13%</span>
                        <div className='d-flex flex-row'>
                            <span className='me-3'>Staking</span>
                            <div className='staking-color'></div>
                        </div>
                    </main>
                    <main className='tokenomic-state d-flex flex-row justify-content-between align-items-center'>
                        <span>12%</span>
                        <div className='d-flex flex-row'>
                            <span className='me-3'>Team</span>
                            <div className='Team-color'></div>
                        </div>
                    </main>
                    <main className='tokenomic-state d-flex flex-row justify-content-between align-items-center'>
                        <span>10%</span>
                        <div className='d-flex flex-row'>
                            <span className='me-3'>Marketing</span>
                            <div className='Marketing-color'></div>
                        </div>
                    </main>
            </div>
            <div className='col-lg-4 col-12 d-flex justify-content-center align-items-center mb-lg-0 mb-5 order-lg-3 order-1' data-aos="zoom-in">
                <Tokenomicschart/>
            </div>
            <div className='col-lg-4 col-12 d-flex flex-column justify-content-center align-items-center order-4' data-aos="fade-left">
                    <main className='tokenomic-state d-flex flex-lg-row-reverse flex-row justify-content-between align-items-center'>
                        <span>7%</span>
                        <div className='d-flex flex-lg-row-reverse flex-row'>
                            <span className='ms-lg-3 me-3'>reserve</span>
                            <div className='reserve-color'></div>
                        </div>
                    </main>
                    <main className='tokenomic-state d-flex flex-lg-row-reverse flex-row justify-content-between align-items-center'>
                        <span>5%</span>
                        <div className='d-flex flex-lg-row-reverse flex-row'>
                            <span className='ms-lg-3 me-3'>Strategic Partners</span>
                            <div className='StartegicP-color'></div>
                        </div>
                    </main>
                    <main className='tokenomic-state d-flex flex-lg-row-reverse flex-row justify-content-between align-items-center'>
                        <span>5%</span>
                        <div className='d-flex flex-lg-row-reverse flex-row'>
                            <span className='ms-lg-3 me-3'>Bounty</span>
                            <div className='Bounty-color'></div>
                        </div>
                    </main>
                    <main className='tokenomic-state d-flex flex-lg-row-reverse flex-row justify-content-between align-items-center'>
                        <span>3%</span>
                        <div className='d-flex flex-lg-row-reverse flex-row'>
                            <span className='ms-lg-3 me-3'>Advisors</span>
                            <div className='Advisors-color'></div>
                        </div>
                    </main>
            </div>
        </div>
        <div className='d-flex justify-content-md-center align-items-md-center mt-5'>
            <a className='btn-styling rounded text-white bg-primary text-decoration-none mb-4 text-center tokenomics-readmore-btn' href='#'>View Full Tockenomics</a>

        </div>
    </section>
  )
}

export default Tokenomics