import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Logo from "../../../Assets/images/Logo.svg"
import {Link} from 'react-router-dom'
const Header = () => {
  return (
    <header className='py-3'>
        <div className='container'>
        <div className='row align-items-center '>
            <a href='#' className='col-lg-4 col-6'>
                <img className='img-fluid' src={Logo} alt='Crypto funding talent logo'/>
            </a>
            <nav className='col-8 d-lg-flex d-none justify-content-between align-items-center'>
                <a href='#' className='text-white text-decoration-none'>About</a>
                <a href='#' className='text-white text-decoration-none'>Service</a>
                <a href='#' className='text-white text-decoration-none'>How it Works</a>
                <a href='#' className='text-white text-decoration-none'>Timeline</a>
                <a href='#' className='text-white text-decoration-none'>Token</a>
                <a href='#' className='text-white text-decoration-none'>Team</a>
                <a href='#' className='btn-styling ms-4 rounded text-white bg-transparent border-white text-decoration-none' style={{border:'1px solid'}}>Whitepaper</a>
            </nav>
            {/* Off Canvas */}
            <button className='d-lg-none d-flex justify-content-end col-6 bg-transparent border-0 text-white fs-5' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                <FontAwesomeIcon icon={faBars}/>
            </button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Backdrop with scrolling</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <p>Try scrolling the rest of the page to see this option in action.</p>
                </div>
            </div>
        </div>
        </div>
    </header>
  )
}

export default Header