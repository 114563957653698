import React from 'react'
import TimelineSwiper from '../../../Utils/TimelineSwiper/TimelineSwiper'
import Logo3D from "../../../Assets/images/Logo3D.svg"
const TheTimeline = () => {
  return (
    <section className='text-white time-line-styling position-relative'>
            <TimelineSwiper/>
            <div className='time-line-3d'>
                <img className='img-fluid' src={Logo3D}/>
            </div>
    </section>
  )
}

export default TheTimeline