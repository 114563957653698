import React from 'react'
import Logo3D from "../../Assets/images/Logo3D.svg"

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import {FreeMode, Mousewheel } from "swiper";

const TimelineSwiper = () => {
  return (
        <>
            <h2 className='container'>The Timeline</h2>
            <Swiper
                direction={"vertical"}
                speed={500}
                slidesPerView={1}
                spaceBetween={0}
                mousewheel={true}
                freeMode={false}
                modules={[FreeMode ,Mousewheel]}
                // onReachEnd={(test)=>{
                //     test.allowSlidePrev=false
                // }}
                className="mySwiper text-white the-timeline-swiper"
                
            >
                
                <SwiperSlide className='container d-flex justify-content-between align-items-center'>
                    <main>
                        <h3>Medium length headline</h3>
                        <ul>
                            <li>Showcase and embed your work with</li>
                            <li>Publish across social channels in a click</li>
                            <li>Sell your videos worldwide</li>
                            <li>Embed your work with</li>
                        </ul>
                    </main>
                </SwiperSlide>
                <SwiperSlide className='container d-flex justify-content-between align-items-center'>
                    <main>
                        <h3>Medium length headline</h3>
                        <ul>
                            <li>Showcase and embed your work with</li>
                            <li>Publish across social channels in a click</li>
                            <li>Sell your videos worldwide</li>
                            <li>Embed your work with</li>
                        </ul>
                    </main>
                </SwiperSlide>
                <SwiperSlide className='container d-flex justify-content-between align-items-center'>
                    <main>
                        <h3>Medium length headline</h3>
                        <ul>
                            <li>Showcase and embed your work with</li>
                            <li>Publish across social channels in a click</li>
                            <li>Sell your videos worldwide</li>
                            <li>Embed your work with</li>
                        </ul>
                    </main>
                </SwiperSlide>
                <SwiperSlide className='container d-flex justify-content-between align-items-center'>
                    <main>
                        <h3>Medium length headline</h3>
                        <ul>
                            <li>Showcase and embed your work with</li>
                            <li>Publish across social channels in a click</li>
                            <li>Sell your videos worldwide</li>
                            <li>Embed your work with</li>
                        </ul>
                    </main>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default TimelineSwiper