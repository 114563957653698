import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import ebay from "../../Assets/icons/ebay.svg"
import cnn from "../../Assets/icons/cnn.svg"
import google from "../../Assets/icons/google.svg"
import cisco from "../../Assets/icons/cisco.svg"
import airbnb from "../../Assets/icons/airbnb.svg"
const PartnerSwiper = () => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination]}
      spaceBetween={-20}
      slidesPerView={3}
      navigation
      pagination={{ clickable: true }}
      breakpoints={{
        576: {
            slidesPerView: 3,
            },
        600: {
            slidesPerView: 3,
            },
        768: {
            slidesPerView: 3,
            },
        992: {
            slidesPerView: 5,
        },
       
    }}
    className='d-flex justify-content-center'
    >
      <SwiperSlide className='d-flex justify-content-center align-items-center'>
        <img className='img-fluid partner-image' src={ebay} alt='Crypto funding talent partners'/>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center align-items-center'>
        <img className='img-fluid partner-image' src={cnn} alt='Crypto funding talent partners'/>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center align-items-center'>
        <img className='img-fluid partner-image' src={google} alt='Crypto funding talent partners'/>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center align-items-center'>
        <img className='img-fluid partner-image' src={cisco} alt='Crypto funding talent partners'/>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center align-items-center'>
        <img className='img-fluid partner-image' src={airbnb} alt='Crypto funding talent partners'/>
      </SwiperSlide>
    </Swiper>
  )
}

export default PartnerSwiper