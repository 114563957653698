import React,{useEffect} from 'react'
import OurServiceSwiperr from '../../../Utils/OurServiceSwiper.js/OurServiceSwiperr'
import Aos from 'aos'
const OurServices = () => {
  useEffect(
    ()=>{
      Aos.init({duration:2000})
    }
  ,[])
  return (
    <section className='container text-white d-flex flex-column justify-content-center align-items-md-center our-services-styling' data-aos="slide-up">
      <main className='d-flex flex-column justify-content-center align-items-center'>
        <h2 className='pb-4 text-center'>Our Services</h2>
        <p className='pt-4 pb-3 text-center'>Separated they live in Bookmarksgrove right at the coast of the famous Semantics</p>
      </main>
      <div className='d-flex flex-md-column flex-column-reverse align-items-md-center'>
        <OurServiceSwiperr/>
        <a className='btn-styling rounded text-white bg-primary text-decoration-none mb-4 text-center' href='#'>Read more</a>
      </div>
    </section>
  )
}

export default OurServices