import React,{useEffect} from 'react'
import World from '../../../Assets/images/world_frame.svg'
import Aos from 'aos'

const SecondSection = () => {
  useEffect(
    ()=>{
      Aos.init({duration:2000})
    }
  ,[])
  return (
    <section className='px-3 mt-lg-5 mt-md-0 mt-5' data-aos="slide-right">
        <div className='row align-items-center second-section-styling'>
            <div className='col-lg-6 col-12 order-lg-1 order-2 d-lg-inline d-md-none d-inline second-section-img-world'>
                <img className='img-fluid' src={World} alt='Crypto funding talent world image'/>
            </div>
            <main className='container col-lg-6 col-12 d-flex flex-column justify-content-center align-items-md-start align-items-enter text-white my-lg-4 my-md-0 my-4 pt-lg-0 pt-md-5 pt-0 order-lg-2 order-1'>
                <h2>Funding Talented Traders Over The World</h2>
                <p className='mt-3 mb-4'>Offering crypto traders access to the Capital they need to boost their profit margins.  welcoming traders over the world to prove their trading skills and unlock up to $1 million trading Account balance. </p>
                <a className='btn-styling rounded text-white bg-primary text-decoration-none mb-4 text-center' href='#'>Read more</a>
            </main>
        </div>
    </section>
  )
}

export default SecondSection