import React,{useEffect} from 'react'
import PlatformFeaturesSwiper from '../../../Utils/PlatfromFeaturesSwiper/PlatformFeaturesSwiper'
import Aos from 'aos'
const PlatformFeatures = () => {
  useEffect(
    ()=>{
      Aos.init({duration:1000})
    }
  ,[])
  return (
    <section className='container platform-features-styling' data-aos="zoom-in">
        <main className='text-white'>
            <h2>Platform Features</h2>
            <p className='mt-4 mb-5'>Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more. </p>
        </main>
        <div>
            <PlatformFeaturesSwiper/>
        </div>
    </section>
  )
}

export default PlatformFeatures