import React,{useEffect} from 'react'
import WhyCftSwiper from "../../../Utils/WhyCft/WhyCftSwiper"
import Aos from 'aos'
const WhyCft = () => {
  useEffect(
    ()=>{
      Aos.init({duration:1000})
    }
  ,[])
  return (
    <section className='container Why-Cft-styling'>
        <main className='text-white'>
            <h2 data-aos="fade-down-left">Why choosing crypto funding talent ?</h2>
            <p className='mt-4 mb-5' data-aos="slide-right">Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more. </p>
        </main>
        <div data-aos="fade-up-right">
            <WhyCftSwiper/>
        </div>
    </section>
  )
}

export default WhyCft