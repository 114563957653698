import React from 'react'
import {Link} from 'react-router-dom'
const IndexError = () => {
  return (
    <div className='vh-100 d-flex flex-column justify-content-center align-items-center error-page-styling'>
        <div className='text-white text-center'>
          <h1 className='pb-5'>404</h1>
          <strong>Page Not found</strong>
        </div>
        <Link to="/" className='mt-5 px-4 py-2 bg-primary rounded text-white text-decoration-none'>Back home</Link>
    </div>
  )
}

export default IndexError