import React,{useEffect} from 'react'
import TeamPic from "../../../Assets/images/teamPic.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSquareFacebook,faTwitter} from '@fortawesome/free-brands-svg-icons'
import Aos from 'aos'

const Team = () => {
    useEffect(
        ()=>{
          Aos.init({duration:1000})
        }
      ,[])
  return (
    <section className='container Meet-Team-styling'>
        <main className='d-flex justify-content-center align-items-center text-white'>
            <h2 className='text-center'>Meet The Team</h2>
        </main>
        <div className='row justify-content-center gap-4 mt-5'>
            <div className='col-lg-4 col-md-6 col-12 team-card' data-aos="flip-right">
                <p>All base UI elements are made using Nested Symbols.</p>
                <div>
                    <div>
                        <img className='img-fluid' src={TeamPic}/>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-end mt-3'>
                        <main className='d-flex flex-column'>
                            <h5>Name Surname</h5>
                            <span>Description</span>
                        </main>
                        <div>
                            <a href='#' className='me-3'><FontAwesomeIcon icon={faSquareFacebook}/></a>
                            <a href='#'><FontAwesomeIcon icon={faTwitter}/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12 team-card' data-aos="flip-right">
                <p>All base UI elements are made using Nested Symbols.</p>
                <div>
                    <div>
                        <img className='img-fluid' src={TeamPic}/>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-end mt-3'>
                        <main className='d-flex flex-column'>
                            <h5>Name Surname</h5>
                            <span>Description</span>
                        </main>
                        <div>
                            <a href='#' className='me-3'><FontAwesomeIcon icon={faSquareFacebook}/></a>
                            <a href='#'><FontAwesomeIcon icon={faTwitter}/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12 team-card' data-aos="flip-right">
                <p>All base UI elements are made using Nested Symbols.</p>
                <div>
                    <div>
                        <img className='img-fluid' src={TeamPic}/>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-end mt-3'>
                        <main className='d-flex flex-column'>
                            <h5>Name Surname</h5>
                            <span>Description</span>
                        </main>
                        <div>
                            <a href='#' className='me-3'><FontAwesomeIcon icon={faSquareFacebook}/></a>
                            <a href='#'><FontAwesomeIcon icon={faTwitter}/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12 team-card' data-aos="flip-right">
                <p>All base UI elements are made using Nested Symbols.</p>
                <div>
                    <div>
                        <img className='img-fluid' src={TeamPic}/>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-end mt-3'>
                        <main className='d-flex flex-column'>
                            <h5>Name Surname</h5>
                            <span>Description</span>
                        </main>
                        <div>
                            <a href='#' className='me-3'><FontAwesomeIcon icon={faSquareFacebook}/></a>
                            <a href='#'><FontAwesomeIcon icon={faTwitter}/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12 team-card' data-aos="flip-right">
                <p>All base UI elements are made using Nested Symbols.</p>
                <div>
                    <div>
                        <img className='img-fluid' src={TeamPic}/>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-end mt-3'>
                        <main className='d-flex flex-column'>
                            <h5>Name Surname</h5>
                            <span>Description</span>
                        </main>
                        <div>
                            <a href='#' className='me-3'><FontAwesomeIcon icon={faSquareFacebook}/></a>
                            <a href='#'><FontAwesomeIcon icon={faTwitter}/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12 team-card' data-aos="flip-right">
                <p>All base UI elements are made using Nested Symbols.</p>
                <div>
                    <div>
                        <img className='img-fluid' src={TeamPic}/>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-end mt-3'>
                        <main className='d-flex flex-column'>
                            <h5>Name Surname</h5>
                            <span>Description</span>
                        </main>
                        <div>
                            <a href='#' className='me-3'><FontAwesomeIcon icon={faSquareFacebook}/></a>
                            <a href='#'><FontAwesomeIcon icon={faTwitter}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Team