import React,{useEffect} from 'react'
import Aos from 'aos'
const HowItWorks = () => {
    useEffect(
        ()=>{
          Aos.init({duration:1000})
        }
      ,[])
  return (
    <section className='container how-it-works-styling'>
        <main className='text-white d-flex flex-column align-items-lg-start align-items-center' data-aos="flip-left">
            <h2>How It Works ?</h2>
            <p className='text-lg-start text-center'>Acme automates your subscription revenue and customer reporting. Just connect your data and Acme will calculate and visualize your most important metrics. </p>
        </main>
        <div className='row gap-4 justify-content-lg-start justify-content-lg-center justify-content-md-start justify-content-center align-items-end position-relative'>
            <div className='col-lg-6 col-12 d-flex flex-column  how-it-works-card' data-aos="zoom-in">
                        <main className='d-flex justify-content-end align-items-end'>
                            <span>01</span>
                        </main>
                        <div>
                            <h3>Get Founded</h3>
                            <p>By creating an Account on our service, you agree to subscribe</p>
                            <ul>
                                <li>Showcase and embed your work</li>
                                <li>Publish across social channels</li>
                                <li>Sell your videos worldwide</li>
                            </ul>
                        </div>
            </div>
            <div className='col-lg-6 col-12 how-it-works-card' data-aos="zoom-in">
                        <main className='d-flex justify-content-end align-items-end'>
                            <span>02</span>
                        </main>
                        <div>
                            <h3>Start Trading</h3>
                            <p>By creating an Account on our service, you agree to subscribe</p>
                            <ul>
                                <li>Showcase and embed your work</li>
                                <li>Publish across social channels</li>
                                <li>Sell your videos worldwide</li>
                                <li>Premium support</li>
                            </ul>
                        </div>
            </div>
            <div className='col-lg-4 col-12 bg-primary text-white how-it-works-card-blue' data-aos="zoom-in">
                <main className='d-flex justify-content-end align-items-end'>
                    <span>03</span>
                </main>
                <div>
                    <h3>Enjoy your 70% profit split</h3>
                    <p>By creating an Account on our service, you agree to subscribe</p>
                    <ul>
                        <li>Showcase and embed your work</li>
                        <li>Publish across social channels</li>
                        <li>Sell your videos worldwide</li>
                        <li>Premium support</li>
                    </ul>
                </div>
            </div>
            <div className='dashed-line d-lg-inline d-none'>
            </div>
        </div>
    </section>
  )
}

export default HowItWorks