import React from 'react'
import Home from './Pages/Home'
import Error from './Pages/Error';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import 'aos/dist/aos.css'
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='*' element={<Error/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App