import React from 'react'
import Index from '../Utils/Header_Footer/Index'
import IndexError from '../Components/Error/IndexError'
const Error = () => {
  return (
    <Index>
        <IndexError/>
    </Index>
  )
}

export default Error