import React from 'react'
import PartnerSwiper from '../../../Utils/PartnerSwiper/PartnerSwiper';
const PartnerSlider = () => {
    return (
        <div className='partner-swiper-styling py-lg-0 py-4'>
            <main className='container partner-slider-styling'>
                <div className='row justify-content-center align-items-center'>
                    <p className='col-lg-3 col-12 text-lg-start text-center text-secondary mb-0'>Given the enormous wealth creation from cryptocurrency</p>
                    <div className='col-lg-9 col-12'>
                        <PartnerSwiper/>
                    </div>
                </div>
            </main>
        </div>
      );
}

export default PartnerSlider