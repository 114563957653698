import React,{useEffect} from 'react'
import PlayIcon from '../../../Assets/icons/PlayIcon.svg'
import Aos from 'aos'
const FirstSection = () => {
  useEffect(
    ()=>{
      Aos.init({duration:2000})
    }
  ,[])
  return (
    <section className='container ' data-aos="fade-right">
      <div className='row first-section-styling '>
        <main className='col-md-6 col-12 d-flex flex-column justify-content-center align-items-start'>
            <h1 className='text-white fw-bold'>Approve your trading skills and get funded up to <strong>$1M</strong></h1>
            <nav className='mt-lg-5 mt-3 row justify-content-center mx-0'>
                <a className='btn-styling col-md-auto col-12 rounded text-white bg-primary text-decoration-none text-md-nowrap text-center mb-4' href='#'>Start investing</a>
                <a className='btn-styling col-12 d-md-none d-inline rounded text-white border text-decoration-none text-center' href='#'>Start investing</a>
            </nav>
        </main>
        <div className='col-md-6 col-12 position-relative play-video'>
          <img className='img-fluid position-absolute'  src={PlayIcon} alt='crypto funding talent play icon'/>
        </div>
      </div>
    </section>
  )
}

export default FirstSection