import React, { useRef, useState,useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import Platform from '../../Assets/images/platform.svg'
import paltform_features_2 from '../../Assets/images/paltform_features_2.svg'
import paltform_features_3 from '../../Assets/images/plaform_features_3.svg'
import paltform_features_4 from '../../Assets/images/paltform_features_4.svg'
import paltform_features_5 from '../../Assets/images/paltform_features_5.svg'

import { FreeMode, Navigation, Thumbs } from "swiper";

const PlatformFeaturesSwiper = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [title,settitle] = useState('')
    useEffect(
        ()=>{
            let width = window.innerWidth
            if(width>900){
                settitle('better trading')
            }
            else{
                settitle('better trad...')
            }
        }
    )
  return (
    <>
        <Swiper
            onSwiper={setThumbsSwiper}
            loop={false}
            spaceBetween={0}
            slidesPerView={3}
            watchSlidesProgress={true}
            navigation={false}
            modules={[FreeMode, Navigation, Thumbs]}
            className="platform-features-navigation"
            breakpoints={{
                576: {
                    slidesPerView: 3,
                    },
                600: {
                    slidesPerView: 3,
                    },
                768: {
                    slidesPerView: 5,
                    },
                992: {
                    width:700,
                    slidesPerView: 5,
                },
                1200: {
                    width:700,
                    slidesPerView: 5,
                }
            }}
        >
            <SwiperSlide>
                <main className="ps-md-4 ps-0 text-md-start text-center text-white platform-feature-font-styling pb-md-3 pb-1">
                    <h3 className="mb-2 d-md-block d-none">01.</h3>
                    <span >Instruments <FontAwesomeIcon className="d-md-none d-inline" icon={faAngleRight}/></span>
                </main>
            </SwiperSlide>
            <SwiperSlide>
                <main className="ps-md-4 ps-0 text-md-start text-center text-white platform-feature-font-styling pb-md-3 pb-1">
                    <h3 className="mb-2 d-md-block d-none">02.</h3>
                    <span>Platform <FontAwesomeIcon className="d-md-none d-inline" icon={faAngleRight}/></span>
                </main>
            </SwiperSlide>
            <SwiperSlide>
                <main className="ps-md-4 ps-0 text-md-start text-center text-white platform-feature-font-styling pb-md-3 pb-1">
                    <h3 className="mb-2 d-md-block d-none">03.</h3>
                    <span>{title} <FontAwesomeIcon className="d-md-none d-inline" icon={faAngleRight}/></span>
                </main>
            </SwiperSlide>
            <SwiperSlide>
                <main className="ps-md-4 ps-0 text-md-start text-center text-white platform-feature-font-styling pb-md-3 pb-1">
                    <h3 className="mb-2 d-md-block d-none">04.</h3>
                    <span>Tools <FontAwesomeIcon className="d-md-none d-inline" icon={faAngleRight}/></span>
                </main>
            </SwiperSlide>
            <SwiperSlide>
                <main className="ps-md-4 ps-0 text-md-start text-center text-white platform-feature-font-styling pb-md-3 pb-1">
                    <h3 className="mb-2 d-md-block d-none">05.</h3>
                    <span>API trading <FontAwesomeIcon className="d-md-none d-inline" icon={faAngleRight}/></span>
                </main>
            </SwiperSlide>
        </Swiper>

        <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2 my-5"
      >
        <SwiperSlide>
          <div className="row align-items-center platform-features-description">
                <div className="col-lg-6 col-12">
                    <img className="img-fluid platform-feature-img" src={Platform} alt='Crypto funding talent instruments'/>
                </div>
                <main className="col-lg-6 col-12 text-white mt-lg-0 mt-4 d-md-block d-flex flex-column">
                    <span className="d-md-block d-none">01</span>
                    <h3 className="platform-features-h3">Instruments</h3>
                    <p className="platform-features-p">
                        Our signature product is supporting traders to overcome the funding barriers. We help traders worry less about funds, and more on making profits.  
                        Once traders prove their ability to ride the market, Crypto Funding Talent will provide them with the capital they need to grow their assets exponentially.
                    </p>
                    <a className="btn-styling rounded text-white text-decoration-none bg-primary text-md-start text-center" href="">Read more</a>
                </main>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row align-items-center platform-features-description">
                <div className="col-lg-6 col-12">
                    <img className="img-fluid platform-feature-img" src={paltform_features_2} alt='Crypto funding talent Platfrom'/>
                </div>
                <main className="col-lg-6 col-12 text-white mt-lg-0 mt-4 d-md-block d-flex flex-column">
                    <span className="d-md-block d-none">02</span>
                    <h3 className="platform-features-h3">Platfrom</h3>
                    <p className="platform-features-p">
                        Our signature product is supporting traders to overcome the funding barriers. We help traders worry less about funds, and more on making profits.  
                        Once traders prove their ability to ride the market, Crypto Funding Talent will provide them with the capital they need to grow their assets exponentially.
                    </p>
                    <a className="btn-styling rounded text-white text-decoration-none bg-primary text-md-start text-center" href="">Read more</a>
                </main>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row align-items-center platform-features-description">
                <div className="col-lg-6 col-12">
                    <img className="img-fluid platform-feature-img" src={paltform_features_3} alt='Crypto funding talent better trading condition'/>
                </div>
                <main className="col-lg-6 col-12 text-white mt-lg-0 mt-4 d-md-block d-flex flex-column">
                    <span className="d-md-block d-none">03</span>
                    <h3 className="platform-features-h3">better trading condition</h3>
                    <p className="platform-features-p">
                        Our signature product is supporting traders to overcome the funding barriers. We help traders worry less about funds, and more on making profits.  
                        Once traders prove their ability to ride the market, Crypto Funding Talent will provide them with the capital they need to grow their assets exponentially.
                    </p>
                    <a className="btn-styling rounded text-white text-decoration-none bg-primary text-md-start text-center" href="">Read more</a>
                </main>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row align-items-center platform-features-description">
                <div className="col-lg-6 col-12">
                    <img className="img-fluid platform-feature-img" src={paltform_features_4} alt='Crypto funding talent Tools'/>
                </div>
                <main className="col-lg-6 col-12 text-white mt-lg-0 mt-4 d-md-block d-flex flex-column">
                    <span className="d-md-block d-none">04</span>
                    <h3 className="platform-features-h3">Tools</h3>
                    <p className="platform-features-p">
                        Our signature product is supporting traders to overcome the funding barriers. We help traders worry less about funds, and more on making profits.  
                        Once traders prove their ability to ride the market, Crypto Funding Talent will provide them with the capital they need to grow their assets exponentially.
                    </p>
                    <a className="btn-styling rounded text-white text-decoration-none bg-primary text-md-start text-center" href="">Read more</a>
                </main>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row align-items-center platform-features-description">
                <div className="col-lg-6 col-12">
                    <img className="img-fluid platform-feature-img" src={paltform_features_5} alt='Crypto funding talent API trading'/>
                </div>
                <main className="col-lg-6 col-12 text-white mt-lg-0 mt-4 d-md-block d-flex flex-column">
                    <span className="d-md-block d-none">05</span>
                    <h3 className="platform-features-h3">API trading</h3>
                    <p className="platform-features-p">
                        Our signature product is supporting traders to overcome the funding barriers. We help traders worry less about funds, and more on making profits.  
                        Once traders prove their ability to ride the market, Crypto Funding Talent will provide them with the capital they need to grow their assets exponentially.
                    </p>
                    <a className="btn-styling rounded text-white text-decoration-none bg-primary text-md-start text-center" href="">Read more</a>
                </main>
          </div>
        </SwiperSlide>
        

      </Swiper>

    </>
  )
}

export default PlatformFeaturesSwiper