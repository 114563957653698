import React from 'react'
import FirstSection from './components/FirstSection'
import PartnerSlider from './components/PartnerSlider'
import SecondSection from './components/SecondSection'
import OurServices from './components/OurServices'
import PlatformFeatures from './components/PlatformFeatures'
import HowItWorks from './components/HowItWorks'
import TheTimeline from './components/TheTimeline'
import WhyCft from './components/WhyCft'
import NewWorldCft from './components/NewWorldCft'
import Tokenomics from './components/Tokenomics'
import Team from './components/Team'
import NewsLetter from './components/NewsLetter'
const Homeindex = () => {
  return (
    <>
        <FirstSection/>
        <PartnerSlider/>
        <SecondSection/>
        <OurServices/>
        <PlatformFeatures/>
        <HowItWorks/>
        <TheTimeline/>
        <WhyCft/>
        <NewWorldCft/>
        <Tokenomics/>
        <Team/>
        <NewsLetter/>
    </>
  )
}

export default Homeindex