import React from 'react'
import {useState,useEffect} from 'react'
import axios from "axios"
import Aos from 'aos'
const NewsLetter = () => {
  const [data,setdata] = useState({
    email:''
  })
  const handlechange = (e)=>{
    setdata(
      (prevemail)=>{
        return {
          ...prevemail,[e.target.name]:e.target.value
        }
      }
    )
  }
  const handleclick = async (e)=>{
    try {
      e.preventDefault();
      const result = await axios.post("http://localhost:5000/api/email",{
        email : data.email
      })
      console.log(result)
      setdata({
        email:''
      })
    } catch (error) {
      console.log(error)
    }
    
  }
  useEffect(
    ()=>{
      Aos.init({duration:1000})
    }
  ,[])
  return (
    <section className='news-letter-styling d-flex flex-column justify-content-center align-items-center my-5 '>
        <h2 className='text-center text-white' data-aos="fade-right">Don’t miss out on our newsletter</h2>
        <form className='mt-4' data-aos="fade-left">
            <input type={'email'} placeholder={'Email'} name="email" value={data.email} onChange={handlechange}/>
            <button className='bg-primary text-white' onClick={handleclick}>Subscribe</button>
        </form>
    </section>
  )
}

export default NewsLetter