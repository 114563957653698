import React,{useEffect} from 'react'
import Logo3D from "../../../Assets/images/Logo3D.svg"
import Aos from 'aos'
import Spline from '@splinetool/react-spline';
const NewWorldCft = () => {
  useEffect(
    ()=>{
      Aos.init({duration:1000})
    }
  ,[])
  return (
    <section className='container text-white NewWorldCft-styling'>
        <div className='row align-items-center'>
            <main className='col-lg-6 col-12 order-lg-1 order-2 mt-lg-0 mt-5 d-flex flex-column justify-content-lg-start justify-content-md-center justify-content-start align-items-lg-start align-items-md-center align-items-start' data-aos="fade-right">
                <h2 className='text-lg-start text-md-center text-start'>Powering an experimental new world with CFT</h2>
                <p className='text-lg-start text-md-center text-start'>This website is operated by the Design Files. Throughout the site, the terms “we”, “us” and “our” refer to the Design Files. The Design Files offers this website, including all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.</p>
            </main>
            <div className='col-lg-6 col-12 order-lg-2 order-1 d-flex justify-content-lg-end justify-content-center' data-aos="fade-left">
              
              <Spline scene="https://prod.spline.design/Ey1V3QhDox654PnK/scene.splinecode" className='spline-3d'/>
            </div>
        </div>
    </section>
  )
}

export default NewWorldCft