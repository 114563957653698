import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
const Index = ({children}) => {
  return (
    <div className='content-wrapper'>
        <Header/>
            {children}
        <Footer/>
    </div>
  )
}

export default Index